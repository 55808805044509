import { BULL } from 'constants/theme';

import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

import './blogitem.scss';

interface Props {
  variant: 'home' | 'large' | 'small';
  to: string;
  image: any;
  title: string;
  category: string;
  date: string;
  readingTime: number;
}

const BlogItem: React.FC<Props> = ({
  variant,
  to,
  image,
  title,
  category,
  date,
  readingTime,
}) => {
  const animation = {
    initial: {
      scale: 1.01,
    },
    hover: {
      scale: 1.2,
      rotate: -5,
    },
  };

  const transition = {
    duration: 1,
    transition: 'easeInOut',
  };

  return (
    <motion.article
      whileHover="hover"
      initial={false}
      className={`blogitem blogitem--${variant}`}>
      <Link to={to} className="blogitem__link">
        <motion.div
          className="blogitem__image"
          variants={animation}
          transition={transition}>
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={title}
          />
        </motion.div>
        <div className="blogitem__box">
          <h2 className="blogitem__title">{title}</h2>
          <h3 className="blogitem__category">{category}</h3>
          <p className="blogitem__label">
            {date} {BULL} {readingTime} min read
          </p>
        </div>
      </Link>
    </motion.article>
  );
};

export default BlogItem;
