import { BLOG_PATH } from 'constants/paths';

import React, { useState, MouseEvent } from 'react';
import { graphql } from 'gatsby';
import { chunk, range } from 'lodash';
import readingTime from 'reading-time';

import Head from 'components/layout/head';
import Layout from 'components/layout';
import { Row, Column } from 'components/grid';
import BlogItem from 'components/blog/blogItem/blogItem';
import MoreLink from 'components/moreLink/moreLink';
import { BlogPost } from 'components/blog';
import { getBlogURL } from 'components/blog/utils';
import Loader from 'components/loader';

interface Props {
  data: {
    allMarkdownRemark: {
      edges: BlogPost[];
    };
  };
}

const BlogIndex: React.FC<Props> = ({ data }) => {
  const [posts] = useState([...data.allMarkdownRemark.edges]);
  const [page, setPage] = useState(1);
  const bound = Math.ceil(posts.length / 3);
  const schema = chunk(posts, 3); // [[{}, {}, {}], [{}]]

  const placeSinglePost = (post: BlogPost) =>
    post ? (
      <BlogItem
        variant="large"
        category={post.node.frontmatter.category}
        title={post.node.frontmatter.title}
        date={post.node.frontmatter.date}
        readingTime={readingTime(post.node.rawMarkdownBody).minutes}
        image={post.node.frontmatter.cover}
        to={getBlogURL(post.node.fields.slug)}
      />
    ) : null;

  const placeDoublePost = (topPost: BlogPost, bottomPost: BlogPost) => {
    const top = topPost ? (
      <BlogItem
        variant="small"
        category={topPost.node.frontmatter.category}
        title={topPost.node.frontmatter.title}
        date={topPost.node.frontmatter.date}
        readingTime={readingTime(topPost.node.rawMarkdownBody).minutes}
        image={topPost.node.frontmatter.cover}
        to={getBlogURL(topPost.node.fields.slug)}
      />
    ) : null;

    const bottom = bottomPost ? (
      <BlogItem
        variant="small"
        category={bottomPost.node.frontmatter.category}
        title={bottomPost.node.frontmatter.title}
        date={bottomPost.node.frontmatter.date}
        readingTime={readingTime(bottomPost.node.rawMarkdownBody).minutes}
        image={bottomPost.node.frontmatter.cover}
        to={getBlogURL(bottomPost.node.fields.slug)}
      />
    ) : null;

    return (
      <>
        {top}
        {bottom}
      </>
    );
  };

  const renderPosts = range(bound).map((index: number) => {
    const isEven = index % 2 === 0;
    const isVisible = index <= page ? '' : 'hidden';

    return (
      <Row key={index} tag="section" className={isVisible}>
        <Column size={isEven ? 7 : 4}>
          {isEven
            ? placeSinglePost(schema[index][0])
            : placeDoublePost(schema[index][0], schema[index][1])}
        </Column>
        <Column size={isEven ? 4 : 7}>
          {isEven
            ? placeDoublePost(schema[index][1], schema[index][2])
            : placeSinglePost(schema[index][2])}
        </Column>
      </Row>
    );
  });

  const showMorePosts = (e: MouseEvent) => {
    e.preventDefault();
    setPage(bound);
  };

  return (
    <Layout>
      <Head pageTitle="Blog – Develop, study, teach" />

      <Row tag="header" className="main__header">
        <Column>
          <h1 className="text-center">Read my stories</h1>
          <p className="lead text-secondary text-center">
            I write about development, productivity and personal improvements
          </p>
        </Column>
      </Row>

      {renderPosts ? renderPosts : <Loader />}

      <Row tag="footer" className="main__footer">
        <Column>
          {page < bound - 1 ? (
            <MoreLink
              to={BLOG_PATH}
              text="Load All Articles"
              style="secondary"
              onClick={e => showMorePosts(e)}
            />
          ) : null}
        </Column>
      </Row>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            category
            title
            date(formatString: "DD. MMMM, YYYY")
            cover {
              childImageSharp {
                gatsbyImageData(width: 702, layout: FIXED)
              }
            }
          }
          rawMarkdownBody
        }
      }
    }
  }
`;

export default BlogIndex;
