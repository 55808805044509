import React from 'react';
import Loading from 'images/icons/loader.svg';

const Loader = () => (
  <p>
    <Loading />
  </p>
);

export default Loader;
